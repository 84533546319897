export default {
  url: '',
  token: null,
  sesudatoken: null,
  error: {
    status: false,
    message: ''
  },
  logo: 'https://sinos.unsam.edu.ar/img/UNSAM_3lineas_NEGRO.png'
}
