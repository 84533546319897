export const SET_SESUDA_TOKEN = (state, token) => {
  state.sesudatoken = token
}

export const SET_REDIRECT_URL = (state, url) => {
  state.url = url
}

export const SET_ERROR_MESSAGE = (state, value) => {
  state.error = value
  setTimeout(() => {
    state.error.status = false
  }, 10000)
}
