<template>
  <v-container fill-height>
    <Message />
    <Login />
  </v-container>
</template>
<script>
import Login from '../components/Login.vue'
import Message from '../components/Message.vue'
export default {
  components: {
    Login,
    Message
  }
}
</script>

<style>
#app {
  background-color: #eeeeee;
}
</style>
