import { authAddrs, authAddrsTest } from '../config'
import router from '@/router'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { signJWT } from '../jwt'

export const googleLogin = async context => {
  try {
    const provider = new GoogleAuthProvider()
    const auth = getAuth()
    auth.languageCode = 'es'
    provider.setCustomParameters({
      login_hint: 'user@unsam.edu.ar'
    })
    const result = await signInWithPopup(auth, provider)
    const { email, displayName, photoURL } = result.user
    const user = { email, displayName, photoURL }
    const token = signJWT(user)
    context.dispatch('gotoUrl', token)
  } catch (error) {
    const errorCode = error?.code
    const errorMessage = error?.message
    const email = error?.email
    const err = {
      status: true,
      message: { errorCode, errorMessage, email }
    }
    context.commit('SET_ERROR_MESSAGE', err)
  }
}

export const gotoUrl = async (context, token) => {
  // default route or selected path
  const selectedRoute = router.history.current.path === '/' ? '' : router.history.current.path.replace('/', '').replace('&test=true', '').replace('test=true', '')
  const testEnv = router.history.current.path.includes('test=true')
  const selectedRouteQuery = selectedRoute.length > 0 ? `&app=${selectedRoute}` : ''
  const authAddrsEnv = testEnv ? `${authAddrsTest}/auth/signin?token=${token}${selectedRouteQuery}` : `${authAddrs}/auth/signin?token=${token}${selectedRouteQuery}`
  location.replace(authAddrsEnv)
}
