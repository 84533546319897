export const firebaseConfig = {
  apiKey: 'AIzaSyAP1T1FtyCDSAkxYDecub6xHG0QcnxVVzE',
  authDomain: 'sinos-auth.firebaseapp.com',
  projectId: 'sinos-auth',
  storageBucket: 'sinos-auth.appspot.com',
  messagingSenderId: '725830726467',
  appId: '1:725830726467:web:31e7f30b5f23561abcb5f6',
  measurementId: 'G-YELK2MKY1G'
}

export const SECRET = 'altosecreto'

export const authAddrs = 'http://sinos.unsam.edu.ar'
export const authAddrsTest = 'http://sinos.test.unsam.edu.ar'
